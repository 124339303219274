<template>
    <div>
        <header-portrate />

        
    <div class="m-2" style="margin-bottom:70px !important;">
        <tabs-comp :tabs="tabs" />

        <v-row class="mt-5">
            <v-col
            cols="12"
            md="10"
            sm="12">
            </v-col>
            <v-col cols="12" md="2" sm="12" class="text-end pt-7">
            <b-button
                v-b-toggle.addNewCard
                class="btn-sm btn "
                @click="resetAllValues({id: 0})"
                style="margin-top:9px;width:165px;background:transparent;border:none !important;margin-left:-7px !important;"
                id="multiply-button" 
                >
                <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add_new_card}}</div>
            </b-button>
            </v-col>
        </v-row>
        <v-row>
            
            <v-col cols="12" md="12" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center backBlack">{{ lang.cardid }}</th>
                            <th class="text-center backBlack">{{lang.date}}</th>
                            <th class="text-center backBlack">{{lang.customer}}</th>
                            <th class="text-center backBlack">{{lang.carstotal}}</th>
                            <th class="text-center backBlack">{{lang.item_totals}}</th>
                            <th class="text-center backBlack">{{lang.created_by}}</th>
                            <th class="text-center backBlack">{{lang.card_supervisor}}</th>
                            <th class="text-center backBlack">{{ lang.status }}</th>
                            <th class="text-center backBlack" colspan="2">{{ lang.action }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in tablerows" :key="index">
                            <td class="text-center" :style="`direction:ltr;width:50px;`">{{item.id}}</td>
                            <td class="text-center" :style="`direction:ltr;`">{{item.card_date_}}</td>
                            <td class="text-center" :style="`direction:ltr;$`">{{item.customer}}</td>
                            <td class="text-center" :style="`direction:ltr;$`">{{item.cars_total}}</td>
                            <td class="text-center" :style="`direction:ltr;`">{{item.item_totals}}</td>
                            <td class="text-center" :style="`direction:ltr;`">{{item.created_by_}}</td>
                            <td class="text-center" :style="`direction:ltr;`">{{item.card_supervisor_}}</td>
                            <td class="text-center" :style="`direction:ltr;`">{{item.status_}}</td>
                            <td class="text-center" :style="`direction:ltr;width:70px;background:blue !important; color:#fff !important;cursor:pointer;border:1px solid #fff !important;`">{{lang.update}}</td>
                            <td class="text-center" :style="`direction:ltr;width:70px;background:blue !important; color:#fff !important;cursor:pointer;border:1px solid #fff !important;`" @click="printIt(item)">{{lang.print}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <Footer />
        <addCarNewCard ref="addNewCard" />
    </div>
    </div>
</template>

<script>
import TabsComp from '@/components/tabsComp.vue';
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import addCarNewCard from '@/components/addCarNewCard.vue'
import Footer from '@/components/Footer.vue';
export default {
  components: { TabsComp, HeaderPortrate,Footer,addCarNewCard},
    data()
     {
        return {
            tablerows: [],
            
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                    text: this.lang.account_statement,
                    disabled: true,
                    to: '/reports/statment-report',
                }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
                const cM = localStorage.getItem('currentMenu');
                if(value.main.index == cM){
                    cMenu = value.sub;
                }else if(cM == null && value.main.index == 1){
                    cMenu = value.sub;
                }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        headers: function(){
            return '';
        }
    },
    created() {
    //    this.getUnits();
    //    this.getRep();
       this.getJobs();
    },
    methods: {
        printIt(item){
            window.open('../api/print.php?neJob='+item.id,"_blank");
        },
        getUnits(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const post = new FormData();
            post.append('type','getUnits');
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[units]',0)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.units = response.data.results.data;
                }
            })
        },
        getJobs(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const post = new FormData();
            post.append('type','getJobs');
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[units]',0)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.tablerows = response.data.results.data;
                }
            })
        },
        resetAllValues(item){
            // console.log(item);
            if(item.id == 0){
                this.$refs.addUnit.resetValues({
                    title: this.lang.add_unit,
                    unit: {
                        id: 0,
                        namear: '',
                        nameen: '',
                        main_unit: 0,
                        type: 1,
                        qty: 1,
                    }
                })
            }else{
                this.$refs.addUnit.resetValues({
                    title: this.lang.update_units,
                    unit: {
                        id: item.id,
                        namear: item.namear,
                        nameen: item.nameen,
                        main_unit: item.main_unit,
                        type: item.type,
                        qty: item.qty,
                    }
                })
            }
        }
    },
}
</script>